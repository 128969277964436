<script>
import { nextTick } from 'vue'
import modal from '/~/core/mdl'
import BaseCollapse from '/~/components/base/collapse/base-collapse.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import GiftDigital from './gift-digital.vue'
import GiftPhysical from './gift-physical.vue'

export default {
  name: 'retailer-gift',
  components: {
    GiftPhysical,
    GiftDigital,
    BaseCollapse,
    BaseIcon,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    physical: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    productLabels: {
      type: Array,
      default: () => [],
    },
    backendErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isClaimsEnabled } = useProvider()
    const { states } = useLocalization()

    return {
      isClaimsEnabled,
      states,
    }
  },
  data() {
    return {
      isOpened: false,
      form: {},
    }
  },
  computed: {
    upgradedForm() {
      const { form } = this

      if (this.physical) {
        return {
          ...form,
          mobile: (form.mobile || '').replace(/\s/g, ''),
          state: (() => {
            const dictState = this.states.find((state) => {
              return state.label === form.state
            })

            return dictState ? dictState.id : ''
          })(),
        }
      }

      return this.form
    },
  },
  watch: {
    isOpened(value) {
      this.$emit('update:visible', value)
      this.form = {
        name: '',
        email: '',
        message: '',
        mobile: '',
        streetAddress: '',
        suburb: '',
        state: '',
        postcode: '',
      }
    },
    form: {
      handler: function () {
        this.$emit('input', this.upgradedForm)

        nextTick(async () => {
          this.$emit(
            'update:filled',
            this.isOpened &&
              (await this.$refs.giftForm?.validationObserverRef?.validate({
                silent: true,
              }))
          )
        })
      },
      deep: true,
    },
  },
  methods: {
    async validate() {
      const form = this.$refs.giftForm

      if (this.isOpened) {
        const isValid = form && (await form.validationObserverRef.validate())

        if (isValid) {
          return new Promise((resolve) => {
            modal.show('retailer-gift-confirmation', {
              props: {
                recipient: this.upgradedForm,
                physical: this.physical,
                labels: this.productLabels,
                onConfirm: () => resolve(true),
                onCancel: () => resolve(false),
              },
            })
          })
        } else {
          return false
        }
      } else {
        return true
      }
    },
    reset() {
      this.isOpened = false
    },
  },
}
</script>

<template>
  <div v-if="!isClaimsEnabled">
    <base-collapse
      v-model="isOpened"
      :disabled="disabled"
      :autoscroll="false"
      :full-width="false"
    >
      <template #trigger>
        <span
          class="flex items-center space-x-2.5"
          :class="{
            'hover:opacity-75': !disabled,
          }"
        >
          <span
            class="flex h-[18px] w-[18px] items-center justify-center rounded border-2 text-white"
            :class="{
              'bg-primary': isOpened,
              'bg-subtle': disabled,
              'border-primary': !disabled,
            }"
          >
            <base-icon
              :class="{ hidden: !isOpened }"
              svg="plain/check"
              :size="14"
            />
          </span>
          <span
            class="font-bold"
            :class="{
              'text-eonx-neutral-600': disabled,
              'text-primary': !disabled,
            }"
          >
            Send as gift
          </span>
        </span>
      </template>
      <div v-if="isOpened" class="my-5 w-full">
        <gift-physical
          v-if="physical"
          ref="giftForm"
          :form="form"
          :backend-errors="backendErrors"
          :disabled="disabled"
          @name="form.name = $event"
          @email="form.email = $event"
          @mobile="form.mobile = $event"
          @streetAddress="form.streetAddress = $event"
          @suburb="form.suburb = $event"
          @state="form.state = $event"
          @postcode="form.postcode = $event"
          @message="form.message = $event"
        />
        <gift-digital
          v-else
          ref="giftForm"
          :form="form"
          :backend-errors="backendErrors"
          :disabled="disabled"
          @name="form.name = $event"
          @email="form.email = $event"
          @message="form.message = $event"
        />
      </div>
    </base-collapse>
  </div>
</template>
